import { ProjectConfig } from '@contember/admin'

export const routes: ProjectConfig['routes'] = {

	dashboard: { path: '/projects' },
	projectCreate: { path: '/projects/new' },
	projectEdit: { path: '/projects/:id' },

	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },

	tagList: { path: '/tags' },
	tagCreate: { path: '/tags/new' },
	tagEdit: { path: '/tags/:id' },

	weList: { path: '/we' },
	weCreate: { path: '/we/new' },
	weEdit: { path: '/we/:id' },

	personList: { path: '/team' },
	personCreate: { path: '/team/new' },
	personEdit: { path: '/team/:id' },

	stageTypeList: { path: '/stagetype' },
	stageTypeCreate: { path: '/stagetype/new' },
	stageTypeEdit: { path: '/stagetype/:id' },

	referenceList: { path: '/reference' },
	referenceCreate: { path: '/reference/new' },
	referenceEdit: { path: '/reference/:id' },
}
